<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12.3721"
      cy="12"
      r="10"
      fill="#25C26E"
    />
    <path
      d="M15.9127 10.0996L11.8874 14.1074L9.47217 11.6922"
      fill="#25C26E"
    />
    <path
      d="M15.9127 10.0996L11.8874 14.1074L9.47217 11.6922"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconSuccessfulSending',
}
</script>

<style lang="scss"></style>
