<template>
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5 56C19.244 56 8.5 45.256 8.5 32C8.5 18.744 19.244 8 32.5 8C45.756 8 56.5 18.744 56.5 32C56.5 45.256 45.756 56 32.5 56Z"
      stroke="#EC4E4E"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.8303 21.3333C31.639 21.3333 31.4664 21.414 31.3452 21.5433C31.2332 21.6626 31.1651 21.8233 31.1663 22C31.1663 22.1189 31.1975 22.2306 31.2522 22.3274C31.3667 22.5299 31.584 22.6666 31.833 22.6666C32.0821 22.6666 32.2994 22.5299 32.4138 22.3274C32.4685 22.2306 32.4997 22.1189 32.4997 22C32.4997 21.7969 32.4087 21.6149 32.2648 21.4926C32.1481 21.3933 31.9964 21.3333 31.8303 21.3333Z"
      fill="#C4C4C4"
    />
    <path
      d="M32.4997 45.3333V32H29.833M32.2648 21.4926C32.4087 21.6149 32.4997 21.7969 32.4997 22C32.4997 22.1189 32.4685 22.2306 32.4138 22.3274M32.2648 21.4926C32.1481 21.3933 31.9964 21.3333 31.8303 21.3333C31.639 21.3333 31.4664 21.414 31.3452 21.5433M32.2648 21.4926L31.8303 22.5416L31.3452 21.5433M31.3452 21.5433C31.2332 21.6626 31.1651 21.8233 31.1663 22C31.1663 22.1189 31.1975 22.2306 31.2522 22.3274M31.2522 22.3274C31.3667 22.5299 31.584 22.6666 31.833 22.6666C32.0821 22.6666 32.2994 22.5299 32.4138 22.3274M31.2522 22.3274L31.833 22L32.4138 22.3274"
      stroke="#EC4E4E"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VWarningRed',
}
</script>
