<template>
  <svg
    width="54"
    height="34"
    viewBox="0 0 54 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3075 15.4575C13.9293 15.4575 15.265 14.0553 15.265 12.5595V8.07224H13.7385V12.466C13.7385 13.2139 13.1661 13.8683 12.4028 13.8683C9.98952 13.9305 7.61844 13.9099 5.23338 13.8891C4.03554 13.8788 2.83394 13.8683 1.62191 13.8683V3.39801C1.62191 2.74362 2.28975 2.08923 2.9576 2.08923H7.6325V0.5H2.9576C1.33569 0.5 0 1.90226 0 3.39801V15.4575H12.3075Z"
      fill="#1E1E1E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.2783 15.551C21.7519 15.551 20.3208 15.0836 19.3667 14.4292V0.687012H21.084V6.85698C21.7519 6.20259 22.8968 5.73517 24.0416 5.73517C26.9037 5.73517 28.6211 7.69834 28.6211 10.5029C28.6211 13.5879 26.5222 15.551 23.2783 15.551ZM26.8084 10.5964C26.8084 8.5397 25.4727 7.3244 23.66 7.3244C22.5151 7.3244 21.561 7.88531 21.084 8.44622V13.3074C21.8472 13.7749 22.5151 13.9618 23.3738 13.9618C25.2819 13.9618 26.8084 12.7465 26.8084 10.5964Z"
      fill="#1E1E1E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.7808 12.9334C37.2084 13.4943 36.0635 13.8682 34.8232 13.8682C32.9151 13.8682 31.6748 12.8399 31.484 11.0637H38.8303C38.9256 10.7833 38.9256 10.4093 38.9256 9.94186C38.9256 7.32433 36.9221 5.6416 34.4415 5.6416C31.2931 5.6416 29.4805 7.79175 29.4805 10.5028C29.4805 13.4943 31.484 15.3639 34.6324 15.3639C35.8726 15.3639 37.3037 14.99 38.2578 14.1487L37.7808 12.9334ZM34.4415 7.32433C36.0635 7.32433 37.113 8.25917 37.2084 9.8484H31.3886C31.5793 8.35265 32.6289 7.32433 34.4415 7.32433Z"
      fill="#1E1E1E"
    />
    <path
      d="M43.6013 14.0555C44.5553 14.0555 45.3186 13.588 45.3186 12.7467C45.3186 11.9545 44.3765 11.6658 43.2908 11.333C41.814 10.8804 40.0712 10.3463 40.0712 8.35292C40.0712 6.76369 41.4069 5.73535 43.3151 5.73535C44.9369 5.73535 46.0818 6.29627 46.6543 6.85717L45.891 8.16595C45.3186 7.60505 44.4599 7.23111 43.5058 7.23111C42.5517 7.23111 41.9793 7.79202 41.9793 8.44641C41.9793 9.30331 42.9609 9.60808 44.0672 9.95159C45.4953 10.395 47.1313 10.9029 47.1313 12.7467C47.1313 14.7098 45.3186 15.5512 43.4104 15.5512C41.884 15.5512 40.3574 14.7098 39.8804 14.0555L40.8344 12.8401C41.4069 13.4946 42.6472 14.0555 43.6013 14.0555Z"
      fill="#1E1E1E"
    />
    <path
      d="M48.5623 11.7183C48.5623 14.1489 49.4209 15.4576 51.5198 15.4576C52.5693 15.4576 53.428 15.0837 54.0004 14.7098L53.428 13.401C53.0463 13.6815 52.5693 13.9619 51.9015 13.9619C50.6611 13.9619 50.1841 13.1206 50.1841 11.6248V7.51149H53.7142V5.92225H50.1841V3.30469H48.4668V11.7183H48.5623Z"
      fill="#1E1E1E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.084 33.3129H19.3667V19.7578H21.084V20.8796C21.7519 20.1317 22.9921 19.5708 24.3279 19.5708C26.9992 19.5708 28.7165 21.5339 28.7165 24.3385C28.7165 27.2365 26.713 29.3866 23.7554 29.3866C22.8013 29.3866 21.7519 29.1996 21.084 28.7323V33.3129ZM26.9992 24.3385C26.9992 22.3753 25.8543 21.0665 23.8508 21.0665C22.7059 21.0665 21.7519 21.6275 21.084 22.3753V27.0495C21.9427 27.6104 22.7059 27.7974 23.66 27.7974C25.7589 27.7974 26.9992 26.3017 26.9992 24.3385Z"
      fill="#1E1E1E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.5907 29.1062H39.2126V20.5991C38.2585 20.0382 36.732 19.5708 35.2055 19.5708C32.0571 19.5708 29.8628 21.7209 29.8628 24.432C29.8628 27.4235 31.6755 29.2932 34.633 29.2932C35.7779 29.2932 36.9228 28.7323 37.5907 28.0778V29.1062ZM34.8239 27.7974C32.9157 27.7974 31.5801 26.5821 31.5801 24.5254C31.5801 22.6557 33.0112 21.0665 35.1101 21.0665C35.8734 21.0665 36.732 21.2535 37.4952 21.6275V26.4886C36.9228 27.143 35.8734 27.7974 34.8239 27.7974Z"
      fill="#1E1E1E"
    />
    <path
      d="M49.8016 19.7578V29.0128C49.8016 32.1913 47.7981 33.5 45.4129 33.5C43.4934 33.5 42.4496 32.8136 41.862 32.4272C41.8363 32.4102 41.8115 32.3939 41.7875 32.3782L42.5507 31.0695C43.1231 31.4434 44.0772 31.9108 45.3175 31.9108C46.9394 31.9108 48.0843 31.0695 48.0843 29.1998V27.7975C47.4164 28.6389 46.1761 29.3867 44.6497 29.3867C42.5507 29.3867 41.1196 28.078 41.1196 25.3669V19.7578H42.8369V24.9929C42.8369 26.7692 43.6002 27.7975 45.3175 27.7975C46.4623 27.7975 47.5119 27.1431 48.0843 26.3952V19.7578H49.8016Z"
      fill="#1E1E1E"
    />
    <path
      d="M9.25457 2.08923H12.6892L8.68213 5.9221L9.73155 6.95043L13.6433 3.21104V6.76346H15.2652V1.34136C15.2652 0.873938 15.0744 0.686969 14.7882 0.593484C14.6927 0.5 14.5973 0.5 14.5019 0.5H9.25457V2.08923Z"
      fill="#00DBE5"
    />
    <path
      d="M15.4566 27.7976V29.1064H7.72871C7.6333 28.7324 7.6333 28.4519 7.6333 28.265C7.6333 26.2122 9.38721 25.1172 10.9693 24.1296C12.2692 23.3182 13.4531 22.5791 13.4531 21.4407C13.4531 20.4123 12.4991 19.6644 11.2587 19.6644C10.3046 19.6644 9.35061 20.2253 8.87358 21.0667L7.6333 20.3189C8.39654 19.1035 9.73224 18.2622 11.4495 18.2622C12.4991 18.2622 13.4531 18.5426 14.1209 19.1035C14.7888 19.6644 15.1704 20.4123 15.1704 21.3471C15.1704 21.908 15.075 22.3755 14.7888 22.8429C14.396 23.6896 13.6798 24.156 12.9065 24.6597C12.7406 24.7677 12.5722 24.8775 12.4036 24.9931C11.9266 25.2735 11.4495 25.554 11.068 25.8344C10.2093 26.3019 9.54142 27.0497 9.54142 27.7976H15.4566Z"
      fill="#00DBE5"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconBestTwoPay',
}
</script>

<style lang="scss"></style>
