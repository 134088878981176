<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12H19C19.663 12 20.2989 11.7366 20.7678 11.2678C21.2366 10.7989 21.5 10.163 21.5 9.5V4.5C21.5 3.83696 21.2366 3.20107 20.7678 2.73223C20.2989 2.26339 19.663 2 19 2H13C12.337 2 11.7011 2.26339 11.2322 2.73223C10.7634 3.20107 10.5 3.83696 10.5 4.5V5"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 5.5C18 6.03043 17.7893 6.53914 17.4142 6.91421C17.0391 7.28929 16.5304 7.5 16 7.5C15.4696 7.5 14.9609 7.28929 14.5858 6.91421C14.2107 6.53914 14 6.03043 14 5.5M6 18.4999L9 18.5M16.925 17.575L15.5 19L16.925 20.425M10 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8H10C10.5304 8 11.0391 8.21071 11.4142 8.58579C11.7893 8.96086 12 9.46957 12 10V19C12 19.5304 11.7893 20.0391 11.4142 20.4142C11.0391 20.7893 10.5304 21 10 21Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 16.1499V16.1503C20.25 16.5245 20.1762 16.8951 20.033 17.2408C19.8898 17.5866 19.6798 17.9007 19.4152 18.1653C19.1505 18.43 18.8364 18.6398 18.4906 18.783C18.1448 18.9262 17.7742 18.9999 17.4 18.9999H15.5"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconAccessCashRegister',
}
</script>

<style lang="scss"></style>
