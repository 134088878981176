<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 14C14.5 15.105 12.933 16 11 16C9.067 16 7.5 15.104 7.5 14M7.5 11C7.5 12.105 9.067 13 11 13C12.933 13 14.5 12.105 14.5 11"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 11V17C7.5 18.104 9.066 19 10.999 19C12.932 19 14.499 18.105 14.499 17V11C14.5 9.895 12.932 9 11 9C9.068 9 7.501 9.896 7.5 11Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 14.859C19.722 14.413 21 12.862 21 11C21 8.791 19.209 7 17 7C17 3.686 14.314 1 11 1C8.363 1 6.129 2.703 5.324 5.068C2.885 5.401 1 7.47 1 10C1 12.047 2.238 13.796 4 14.569"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconIntegration',
}
</script>

<style lang="scss"></style>
