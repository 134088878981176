<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8142 18.6405H6.46091C3.59341 18.6405 1.66675 16.628 1.66675 13.633V6.70464C1.66675 3.70964 3.59341 1.69714 6.46091 1.69714H9.57675C9.92175 1.69714 10.2017 1.97714 10.2017 2.32214C10.2017 2.66714 9.92175 2.94714 9.57675 2.94714H6.46091C4.30758 2.94714 2.91675 4.42214 2.91675 6.70464V13.633C2.91675 15.9155 4.30758 17.3905 6.46091 17.3905H13.8142C15.9676 17.3905 17.3592 15.9155 17.3592 13.633V10.2763C17.3592 9.93131 17.6392 9.65131 17.9842 9.65131C18.3292 9.65131 18.6092 9.93131 18.6092 10.2763V13.633C18.6092 16.628 16.6817 18.6405 13.8142 18.6405Z"
      fill="#80869A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.38974 12.8569H9.87058C10.1872 12.8569 10.4839 12.7344 10.7081 12.5102L16.9656 6.2527C17.2222 5.99604 17.3639 5.65437 17.3639 5.29104C17.3639 4.92687 17.2222 4.58437 16.9656 4.3277L15.9514 3.31354C15.4206 2.78437 14.5572 2.78437 14.0256 3.31354L7.79808 9.54104C7.58224 9.75687 7.45974 10.0435 7.45224 10.3477L7.38974 12.8569ZM9.87058 14.1069H6.74891C6.58058 14.1069 6.41891 14.0385 6.30141 13.9177C6.18391 13.7977 6.11974 13.6352 6.12391 13.466L6.20224 10.3169C6.21808 9.6902 6.47058 9.10104 6.91391 8.65687H6.91474L13.1422 2.42937C14.1606 1.4127 15.8164 1.4127 16.8347 2.42937L17.8489 3.44354C18.3431 3.93687 18.6147 4.5927 18.6139 5.29104C18.6139 5.98937 18.3422 6.64437 17.8489 7.13687L11.5914 13.3944C11.1322 13.8535 10.5206 14.1069 9.87058 14.1069Z"
      fill="#80869A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4425 8.26376C16.2825 8.26376 16.1225 8.20292 16.0008 8.08042L12.1958 4.27542C11.9517 4.03126 11.9517 3.63542 12.1958 3.39125C12.44 3.14709 12.835 3.14709 13.0792 3.39125L16.8842 7.19709C17.1283 7.44126 17.1283 7.83626 16.8842 8.08042C16.7625 8.20292 16.6025 8.26376 16.4425 8.26376Z"
      fill="#80869A"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconEdit',
}
</script>
