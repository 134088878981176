<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.00023V18.0002M15.99 5.99023H7.99M12 14.0002V18.0002M15 18.0002H9M22 6.00023H2M20 18.0002V6.00023M8 11.0002H16H15.99C16.54 10.9902 16.99 11.4402 16.99 11.9902V12.4902C16.99 13.3102 16.31 13.9902 15.49 13.9902C15.48 13.9902 15.48 13.9902 15.48 13.9902H8.48H8.47C7.64 13.9802 6.97 13.3102 6.97 12.4902V11.9902C6.96 11.4302 7.41 10.9802 7.96 10.9802L8 11.0002Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconTablesWaiters',
}
</script>

<style lang="scss"></style>
