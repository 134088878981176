<template>
  <svg
    width="108"
    height="25"
    viewBox="0 0 108 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.09697 5.75928V8.77898C7.81768 8.72758 7.56428 8.70258 7.33715 8.70258C6.06147 8.70258 5.06596 9.06234 4.34923 9.78095C3.63296 10.5004 3.2753 11.5362 3.2753 12.8891V19.4614H0V5.9116H1.5994L3.11847 7.89049C4.06186 6.46906 5.7215 5.75928 8.09697 5.75928Z"
      fill="#1E1E1E"
    />
    <path
      d="M21.6147 19.4614H18.3394V4.85775L21.6147 0.633301V19.4614Z"
      fill="#1E1E1E"
    />
    <path
      d="M46.1305 13.7021H35.1509C35.3426 14.6999 35.8456 15.4902 36.6575 16.0741C37.4694 16.6575 38.4788 16.9497 39.6846 16.9497C41.2214 16.9497 42.488 16.4593 43.4835 15.4777L45.1102 17.5766C44.481 18.3039 43.8154 18.709 42.8552 19.0809C41.8937 19.4536 40.8117 19.6392 39.6058 19.6392C38.0681 19.6392 36.7139 19.3434 35.5439 18.7516C34.3734 18.1595 33.4692 17.3348 32.8318 16.2774C32.1935 15.2198 31.8751 14.0234 31.8751 12.6863C31.8751 11.3667 32.1854 10.1787 32.805 9.12159C33.4253 8.06413 34.2864 7.23951 35.3866 6.64732C36.4873 6.0556 37.7281 5.75928 39.1075 5.75928C40.4707 5.75928 41.6886 6.05097 42.763 6.63435C43.8378 7.21866 44.6765 8.03913 45.279 9.09612C45.8814 10.1536 46.1831 11.376 46.1831 12.7626C46.1836 12.9822 46.165 13.2951 46.1305 13.7021ZM36.4485 9.19753C35.7232 9.7985 35.2823 10.5972 35.1245 11.595H43.0652C42.925 10.6148 42.4976 9.81889 41.7814 9.21053C41.0641 8.60117 40.1738 8.2961 39.108 8.2961C38.0594 8.29564 37.1729 8.59656 36.4485 9.19753Z"
      fill="#1E1E1E"
    />
    <path
      d="M62.4301 13.7021H51.4504C51.6417 14.6999 52.1446 15.4902 52.9566 16.0741C53.7694 16.6575 54.7783 16.9497 55.9837 16.9497C57.5205 16.9497 58.7871 16.4593 59.7835 15.4777L61.3744 17.5913C60.7456 18.3187 60.1154 18.709 59.1543 19.0809C58.1937 19.4536 57.1107 19.6392 55.9048 19.6392C54.3676 19.6392 53.013 19.3434 51.8434 18.7516C50.6725 18.1595 49.7683 17.3348 49.1309 16.2774C48.4935 15.2198 48.1741 14.0234 48.1741 12.6863C48.1741 11.3667 48.485 10.1787 49.1051 9.12159C49.7243 8.06413 50.5854 7.23951 51.6857 6.64732C52.7869 6.0556 54.0271 5.75928 55.4066 5.75928C56.7698 5.75928 57.9881 6.05097 59.063 6.63435C60.1369 7.21866 60.9756 8.03913 61.5781 9.09612C62.1815 10.1536 62.4822 11.376 62.4822 12.7626C62.4822 12.9822 62.465 13.2951 62.4301 13.7021ZM52.7471 9.19753C52.0223 9.7985 51.5819 10.5972 51.4241 11.595H59.3647C59.2241 10.6148 58.7962 9.81889 58.0799 9.21053C57.3632 8.60117 56.4724 8.2961 55.4066 8.2961C54.3585 8.29564 53.472 8.59656 52.7471 9.19753Z"
      fill="#1E1E1E"
    />
    <path
      d="M77.0389 6.62139C78.1136 7.19688 78.9565 8.00951 79.5677 9.05773C80.1788 10.1069 80.485 11.3158 80.485 12.6868C80.485 14.0563 80.1788 15.2708 79.5677 16.3278C78.9565 17.3853 78.1136 18.2011 77.0389 18.7766C75.9642 19.3512 74.7459 19.6392 73.3834 19.6392C71.4966 19.6392 70.0024 19.0304 68.9027 17.8127V24.3846H65.6266V5.9116H67.4414L68.7453 7.68722C69.2866 7.04455 69.9544 6.56259 70.7499 6.2408C71.5441 5.9204 72.4232 5.75928 73.3834 5.75928C74.7459 5.75928 75.9642 6.0468 77.0389 6.62139ZM75.9912 15.7569C76.7682 14.9796 77.1574 13.9549 77.1574 12.6868C77.1574 11.4181 76.7682 10.3944 75.9912 9.61608C75.2128 8.83776 74.221 8.44838 73.0165 8.44838C72.2309 8.44838 71.5223 8.62295 70.8941 8.96925C70.2652 9.31609 69.7667 9.81103 69.3999 10.4542C69.0337 11.0968 68.85 11.8408 68.85 12.6868C68.85 13.5322 69.0337 14.2767 69.3999 14.9193C69.7667 15.562 70.2652 16.0569 70.8941 16.4042C71.5223 16.751 72.2309 16.9247 73.0165 16.9247C74.221 16.9247 75.2128 16.5353 75.9912 15.7569Z"
      fill="#1E1E1E"
    />
    <path
      d="M96.7061 13.7021H85.7257C85.918 14.6999 86.4204 15.4902 87.2324 16.0741C88.0444 16.6575 89.0546 16.9497 90.2597 16.9497C91.7967 16.9497 93.0631 16.4593 94.0588 15.4777L95.6735 17.5691C95.044 18.2965 94.3913 18.709 93.4299 19.0809C92.4691 19.4536 91.3871 19.6392 90.1807 19.6392C88.643 19.6392 87.289 19.3434 86.1188 18.7516C84.9479 18.1595 84.0444 17.3348 83.407 16.2774C82.7688 15.2198 82.4501 14.0234 82.4501 12.6863C82.4501 11.3667 82.7603 10.1787 83.3813 9.12159C84.0003 8.06413 84.8617 7.23951 85.9614 6.64732C87.0625 6.0556 88.3025 5.75928 89.6828 5.75928C91.046 5.75928 92.2643 6.05097 93.3383 6.63435C94.4131 7.21866 95.2514 8.03913 95.854 9.09612C96.4565 10.1536 96.7581 11.376 96.7581 12.7626C96.7581 12.9822 96.741 13.2951 96.7061 13.7021ZM87.023 9.19753C86.2979 9.7985 85.8567 10.5972 85.7 11.595H93.64C93.4997 10.6148 93.0723 9.81889 92.3558 9.21053C91.6393 8.60117 90.7477 8.2961 89.6828 8.2961C88.6344 8.29564 87.748 8.59656 87.023 9.19753Z"
      fill="#1E1E1E"
    />
    <path
      d="M108 5.75928V8.77898C107.719 8.72758 107.467 8.70258 107.239 8.70258C105.964 8.70258 104.969 9.06234 104.252 9.78095C103.536 10.5004 103.178 11.5362 103.178 12.8891V19.4614H99.9019V5.9116H101.532L103.021 7.89049C103.964 6.46906 105.623 5.75928 108 5.75928Z"
      fill="#1E1E1E"
    />
    <path
      d="M30.871 19.4616L25.5196 12.5244L30.7089 5.88582L27.5746 5.86035L22.3681 12.5202L22.3886 12.535L27.7128 19.437L30.871 19.4616Z"
      fill="#1E1E1E"
    />
    <path
      d="M19.7063 21.9103H5.03577L6.94403 19.4619H21.6146L19.7063 21.9103Z"
      fill="#00CF6F"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconRKeeperLogo',
}
</script>

<style lang="scss"></style>
