<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6.8501H21M17 21.0001C14.239 21.0001 12 18.7611 12 16.0001C12 13.2391 14.239 11.0001 17 11.0001C19.762 11.0001 22 13.2391 22 16.0001C22 18.7611 19.762 21.0001 17 21.0001Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 13V5C21 3.895 20.105 3 19 3H4C2.895 3 2 3.895 2 5V14C2 15.105 2.895 16 4 16H12M17 14V18M19 16H15"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconCompanyDetails',
}
</script>

<style lang="scss"></style>
