<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.248 17.341L20.762 16.825C21.224 17.922 21.012 19.236 20.121 20.128C19.406 20.845 18.424 21.115 17.499 20.956M15.752 18.658L15.238 19.174C14.776 18.077 14.988 16.763 15.879 15.871C16.637 15.11 17.698 14.854 18.67 15.078M11 20H5C3.895 20 3 19.105 3 18V7.5C3 6.672 3.672 6 4.5 6H17.5C18.328 6 19 6.672 19 7.5V11M7 6V5.75C7 3.679 8.679 2 10.75 2H11.25C13.321 2 15 3.679 15 5.75V6"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconConnectionsCheckout',
}
</script>

<style lang="scss"></style>
